import { Action, State, StateContext } from '@ngxs/store';
import { ConfirmShipmentStateModel } from '@store/mobile/confirm-shipment/confirm-shipment.model';
import {
  AddBoxConfirmShipment,
  ResetBoxConfirmShipment,
  ResetConfirmShipment,
  ScanBoxConfirmShipment,
  ScanOrderConfirmShipment,
} from '@store/mobile/confirm-shipment/confirm-shipment.actions';
import { Injectable } from '@angular/core';

@State<ConfirmShipmentStateModel>({
  name: 'confirmShipment',
  defaults: {
    scannedBoxes: [],
  },
})
@Injectable()
export class ConfirmShipmentState {
  @Action(ScanOrderConfirmShipment)
  public setScannedCode(ctx: StateContext<ConfirmShipmentStateModel>, { shipment }: ScanOrderConfirmShipment): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      shipment,
    });
  }

  @Action(ScanBoxConfirmShipment)
  public setCurrentBox(ctx: StateContext<ConfirmShipmentStateModel>, { currentBox }: ScanBoxConfirmShipment): void {
    ctx.patchState({
      currentBox,
    });
  }

  @Action(AddBoxConfirmShipment)
  public addBox(ctx: StateContext<ConfirmShipmentStateModel>, { box }: AddBoxConfirmShipment): void {
    const state = ctx.getState();
    const scannedBoxes = [...state.scannedBoxes, box];

    ctx.patchState({
      ...state,
      scannedBoxes,
    });
  }

  @Action(ResetBoxConfirmShipment)
  public resetBox(ctx: StateContext<Partial<ConfirmShipmentStateModel>>): void {
    ctx.patchState({
      currentBox: null,
    });
  }

  @Action(ResetConfirmShipment)
  public reset(ctx: StateContext<Partial<ConfirmShipmentStateModel>>): void {
    ctx.setState({
      scannedBoxes: [],
    });
  }
}
